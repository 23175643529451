import React, { useState } from 'react';
import AceEditor from "react-ace";
import Layout from "./layout"
import Seo from "./seo"
import "ace-builds/src-noconflict/mode-python";
import "ace-builds/src-noconflict/theme-nord_dark";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container } from 'react-bootstrap'

export default function CodeRunner() {


    function runCode() {
        console.log('Code to run \n' + code);
        fetch(`https://pgillserver.com/python?` + new URLSearchParams({
            code: code
        })).then(res => res.json())
            .then(result => {
                console.log(result.message)
                setOutput(result.message)
            })
            .catch(() => {
                console.log('No response')
            })

    }
    const [output, setOutput] = useState('')
    const [code, setCode] = useState('print("Hi!")')

    return (

        <>
            <Seo title="Projects" />
            <Container fluid  >
                <div className='row justify-content-center'>

                    <AceEditor
                        className='col '
                        mode='python'
                        fontSize='20px'
                        value={code}
                        theme='nord_dark'
                        onChange={(newValue) => setCode(newValue)}
                    />
                    <div className='col-md-1 ' style={{ backgroundColor: '#2E3440' }}   >
                        <button onClick={() => { runCode() }} type="button" className="btn btn-success p-1">Run</button>


                    </div>
                </div>
                <div className='row justify-content-center'>
                    <AceEditor
                        className='col '
                        maxLines='15'
                        fontSize='20px'
                        showGutter='false'
                        readOnly
                        height='100px'
                        className='col'
                        mode='python'
                        value={output}
                        theme='nord_dark'
                    />


                </div>

            </Container>
        </>
    )
}
