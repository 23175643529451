import React from 'react'
import CodeRunner from '../components/CodeRunner';
import Layout from "../components/layout"
import Seo from "../components/seo"
import PageHead from "../components/PageHead"
export default function DockerRunner() {
    return (
        <Layout>

            <Seo title="Projects" />
            <PageHead title="Docker runner"
                description="Run code on my node server hosted on AWS. Code is ran and compiled in 
                docker containers." />

            <div className='container'>
                <CodeRunner />
            </div>
        </Layout>
    )
}
